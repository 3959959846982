export interface ContainerStyle {
  width: string | number;
  height: string | number;
}

export interface GoogleMapsGeolocation {
  lat: number;
  lng: number;
}

export interface Icon {
  path: string;
  fillOpacity: number;
  strokeWeight: number;
  rotation: number;
  scale: number;
}

export interface GMap {
  containerStyle: ContainerStyle;
  defaultLocation: GoogleMapsGeolocation;
  zoom: number;
  icon: Icon;
}

export const gMap: GMap = {
  containerStyle: {
    width: "100%",
    height: "100%",
  },
  defaultLocation: {
    lat: Number(process.env.REACT_APP_LATITUDE!),
    lng: Number(process.env.REACT_APP_LONGITUDE!),
  },
  zoom: 14,
  icon: {
    path:
      "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
  },
};

import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {LocationByAddress, useGeolocation} from 'src/hooks/geolocation.hook';

export const SearchRestaurant = observer(() => {
	const {translate} = useTranslate();
	const {locationByAddress, cancelLocationByAddress} = useGeolocation();
	const [address, setAddress] = useState<string>('');

	const {
		restaurant: {fetchRestaurants},
	} = useStore();

	const sendSearch = async () => {
		locationByAddress(address!)
			.then(({fullAddress, geolocation}: LocationByAddress) => {
				setAddress(fullAddress);
				fetchRestaurants(geolocation);
			})
			.catch(() => fetchRestaurants({lat: 1, lng: 1}));
	};

	const cancelSearch = async () => {
		const hasSearchRunning = await cancelLocationByAddress;
		if (hasSearchRunning) cancelLocationByAddress();
		setAddress('');
		fetchRestaurants();
	};

	return (
		<div className="restaurants__search search bg-secondary-dark">
			<div className="search__field bg-white">
				<input
					type="text"
					className="search__input txt-s txt-grey-90"
					placeholder={translate('RestaurantsPage.search.placeholder')}
					value={address}
					onChange={({currentTarget}) => setAddress(currentTarget.value)}
					onKeyDown={({key}) => key === 'Enter' && address && address.length >= 3 && sendSearch()}
				/>
				<button
					className="search__button search__button--search txt-grey-80"
					onClick={() => sendSearch()}
					title="search"
					disabled={(address && address.length < 3) || !address}>
					<i className="fas fa-search" title="search"></i>
				</button>
				<button
					className="search__button search__button--clear txt-grey-80"
					onClick={() => cancelSearch()}
					title="clear"
					disabled={!address}>
					<i className="fas fa-times" title="clear"></i>
				</button>
			</div>
		</div>
	);
});

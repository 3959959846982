import {BrandBannerObject} from '@acrelec-cloud/apico-shared';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {Autoplay, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react.js';

import defaultImage from 'src/assets/images/carls-jr-default-image.jpg';
import {LoginComponent} from 'src/components/Auth/Login/LoginComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {SliderImage} from 'src/components/Slider/SliderImage';
import {useStore} from 'src/contexts/store.context';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/swiper.scss';

export const HomeMenuPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('HomeMenuPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	const {
		auth: {isLogged},
		brand: {fetchBanners, banners},
	} = useStore();

	const [displayError, setDisplayError] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		fetchBanners().then(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const homeContent = useMemo(() => {
		return isLogged ? (
			<div className="home-main__btn-container">
				<Link to="/order" className="home-main__btn btn__primary">
					{translate('HomeMenuPage.button')}
				</Link>
			</div>
		) : (
			<div className="home-main__login">
				{displayError && <p>{translate(`Profile.error`)}</p>}
				<LoginComponent onError={setDisplayError}></LoginComponent>
			</div>
		);
	}, [displayError, isLogged, translate]);

	return (
		<main className="home-main">
			<div className={isLogged ? 'home-main__content--center' : 'home-main__content'}>
				<div
					className={isLogged ? 'home-main__content--center--logged' : 'home-main__content__left'}>
					<h1 className="home-main__content__left__title profile__title txt-xxl">
						{translate('HomeMenuPage.welcome')}
					</h1>
					{!isLoading && banners && banners.banners ? (
						<Swiper
							modules={[Pagination, Autoplay]}
							pagination={{
								el: '.my-custom-pagination-div',
								clickable: true,
								bulletClass: `swiper-pagination-bullet`,
								bulletActiveClass: 'swiper-pagination-bullet-active',
							}}
							slidesPerView={1}
							autoplay={{
								delay: 5000,
								disableOnInteraction: false,
							}}
							className="home-main__content__left__swiper">
							<div className="my-custom-pagination-div" />
							{banners.banners.length > 0 ? (
								banners.banners.map((banner: BrandBannerObject) => {
									return (
										<SwiperSlide key={banner.id} className="swiper-slide">
											<SliderImage src={banner.url} />
										</SwiperSlide>
									);
								})
							) : (
								<SwiperSlide className="swiper-slide">
									<SliderImage src={defaultImage} />
								</SwiperSlide>
							)}
						</Swiper>
					) : (
						<LoadingSpinner />
					)}
				</div>
				{homeContent}
			</div>
		</main>
	);
});

import {observer} from 'mobx-react-lite';
import React, {useCallback, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {showDispatchersChildren} from 'src/components/Products/Parts/utils/toggle-children.util';

interface ToggleButtonProps {
	display: boolean;
	isOption: boolean;
	step?: string;
	onCustomizeClick?: () => void;
}

export const ToggleButton = observer(
	({display, isOption, step, onCustomizeClick}: ToggleButtonProps) => {
		const {translate} = useTranslate();
		const [open, setOpen] = useState<boolean>(false);

		const onToggle = useCallback(
			(event: any) => {
				if (isOption) {
					// toggleDispatchersChildren allows us to open many option lists at the same time
					// toggleDispatchersChildren(event);

					// showDispatchersChildren allows us to open only one option list at a time
					showDispatchersChildren(event);
					setOpen(!open);
				} else {
					if (onCustomizeClick) {
						onCustomizeClick();
					}
				}
			},
			[isOption, open, onCustomizeClick],
		);

		return (
			<>
				{display && (
					<span
						className={
							isOption
								? 'parts__expand-btn txt-secondary-dark txt-m'
								: 'parts__expand-btn  txt-primary-dark txt-xs'
						}
						onClick={(e) => onToggle(e)}>
						{translate(
							`Parts.part_title.${isOption ? 'option' : 'regular'}.${open ? 'hide' : 'show'}`,
						)}
						{step && isOption && ` ${step}`}
						<i
							className={
								isOption ? 'arrow fas fa-chevron-down txt-m' : ''
							}></i>
					</span>
				)}
			</>
		);
	},
);

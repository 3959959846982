import {observer} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useClearCache} from 'react-clear-cache';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import ReactNotification from 'react-notifications-component';
import store from 'store';

import {StoreProvider} from 'src/contexts/store.context';
import {GlobalRouter} from 'src/routers/GlobalRouter';

import LanguagesController from './components/Languages/LanguagesController';

require(process.env.REACT_APP_THEME_PATH!);

export const App = observer(() => {
	const {isLatestVersion, emptyCacheStorage} = useClearCache();

	const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;

	useEffect(() => {
		const timer = setInterval(() => {
			store.clearAll(); // to clear local storage
			emptyCacheStorage();
		}, 1000 * 60 * 60 * 24); // 24h

		return () => clearInterval(timer);
	}, [emptyCacheStorage]);

	useEffect(() => {
		if (!isLatestVersion) {
			store.clearAll(); // to clear local storage
			emptyCacheStorage(); // required to add the current APP_VERSION in the storage
		}
	}, [emptyCacheStorage, isLatestVersion]);

	return (
		<GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
			<StoreProvider>
				<ReactNotification />
				<LanguagesController>
					<GlobalRouter />
				</LanguagesController>
			</StoreProvider>
		</GoogleReCaptchaProvider>
	);
});

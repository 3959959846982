import {ProductObject, RestaurantOutageProductsObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {useImage} from 'src/hooks/image.hook';
import {useMoney} from 'src/hooks/money.hook';

interface ProductTileProps {
	item: ProductObject;
}

export const ProductTile = observer((props: ProductTileProps) => {
	const {item} = props;
	const [formatMoney] = useMoney();
	const {image} = useImage(item.imageUrl);
	const {translate, translateProduct} = useTranslate();

	const {
		navigation: {getProductPrice},
    restaurant: {currentRestaurant},
	} = useStore();

  const productUnavailable = 
    (currentRestaurant.outageProducts && 
      currentRestaurant.outageProducts.find((outage: RestaurantOutageProductsObject) => outage.productCode === item.code));

	return (
		<div className="product-item__button">
			{item.price && (
				<div className="product-item--price txt-xxs txt-bold">
					<span className="txt-tertiary txt-m">{formatMoney(getProductPrice(item.code))}</span>
				</div>
			)}
			<div className="product-item__container">
				<div className="product-item__image-container">
					<img src={image} alt={translateProduct(item).name} className="product-item__image" />
				</div>
				<div className="product-item--infos txt-xleft">
					<h2 className="txt-xleft product-item--title">{translateProduct(item).name}</h2>
					{!!item.calories && (
						<span className="txt-s txt-secondary">{`${item.calories}  ${translate(
							`App.calories`,
						)}`}</span>
					)}
				</div>
        {productUnavailable && (
          <div className="product-item__unavailable">
            <p>{translate(`App.unavailable`)}</p>
          </div>
        )}
			</div>
		</div>
	);
});

import {CustomizationStoreType} from '@acrelec-cloud/apico-cdk';
import {observer} from 'mobx-react-lite';
import {getSnapshot} from 'mobx-state-tree';
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';

import {numberPattern} from 'src/components/Form/validator-form.config';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {useStore} from 'src/contexts/store.context';
import {rootStore} from 'src/stores/root.store';

export const DiscountComponent = observer(() => {
	const {
		customization: {fetchProduct},
		basket: {addBasketProduct, isDiscountValid},
	} = useStore();
	const {translate} = useTranslate();
	const [isError, setIsError] = useState(false);
	const [validDiscount, setValidDiscount] = useState<string | undefined>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const removeDiscountMessage = (): void => {
		setIsError(false);
		setValidDiscount(undefined);
	};

	const {errors, register, formState, handleSubmit} = useForm({
		mode: 'onChange',
  });

	const onSubmit = (data: any) => {
		removeDiscountMessage();
		setIsLoading(true);
		fetchProduct(data.discount)
			.then((response: any) => {
				const isCouponValid = isDiscountValid(response, process.env.REACT_APP_PROTECTION_CODE);
				if (isCouponValid) {
					setIsLoading(false);
					setValidDiscount(response.description);
					const currentCustomization: CustomizationStoreType = getSnapshot(
						rootStore['customization'],
					);
					const discountProduct = {
						...currentCustomization,
						quantity: 1,
						currentProductParts: [],
						parts: [],
						required: [],
					};
          const maxQtyProduct = Number(process.env.REACT_APP_MAX_BASKET_PRODUCT_QTY);
					addBasketProduct(discountProduct, maxQtyProduct);
				} else {
					setIsLoading(false);
					setIsError(true);
				}
			})
			.catch(() => {
				setIsLoading(false);
				setIsError(true);
			});
	};

	return (
		<div className="checkout-page-left__content-wrapper select-hour__wrapper content-wrapper--padding">
			<div>
				<h2 className="txt-h2">{translate('CheckoutPage.discount.title')}</h2>
				<form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
					<div className="discount__container">
						<div className="form-input__container form-input__container--discount">
							{/* TODO problem sur safari du label */}
							<label className="discount__container__label">
								{translate('CheckoutPage.discount.label')}
							</label>
							<input
								className="form-input__border form-input__border--discount txt-s"
								ref={register({
									pattern: {
										value: numberPattern,
										message: 'Form.errors.pattern.number',
									},
								})}
								name="discount"
								id="discount"
								type="text"
								onChange={removeDiscountMessage}
							/>
						</div>

						<div className="form__discount__error">
							{errors.discount && (
								<span className="txt-error ">{translate(errors.discount.message)}</span>
							)}
						</div>
					</div>

					<div className="form-input__action discount-button__container">
						<button
							type="submit"
							disabled={!formState.isValid}
							className="form__btn btn__primary discount-button__container__btn">
							{isLoading ? (
								<LoadingSpinner
									height={'20px'}
									width={'20px'}
								/>
							) : (
								translate('CheckoutPage.discount.button')
							)}
						</button>
					</div>
				</form>

				{isError && <p className="coupon-error">{translate('CheckoutPage.discount.error')}</p>}

				{validDiscount && <p className="txt-success">{validDiscount}</p>}
			</div>
		</div>
	);
});

import {
  OpeningHours,
  OpeningHoursDay,
  todaysOpeningHours,
} from "@acrelec-cloud/apico-cdk";
import { RestaurantObject } from "@acrelec-cloud/apico-sdk";
import { DateTime } from "luxon";

import { useTranslate } from "src/components/Languages/translate.hook";
import { useStore } from "src/contexts/store.context";

import { useEasyDateHour } from "./easy-date-hour.hook";

export function useOpeningHours() {
  const {
    restaurant: { currentRestaurant },
    basket: { pickUpTime, setPickUpTime },
  } = useStore();
  const { currentDayName, formatHours } = useEasyDateHour();
  const { translate } = useTranslate();
  const currentDate = () =>
    DateTime.local().setZone(currentRestaurant.timeZone);

  const handleOpeningHours = (
    feature: "CURRENT" | "LIST",
    value: OpeningHours[]
  ) => {
    const openingHoursList: OpeningHours[] = [];
    for (const currentValue of value) {
      if (feature === "LIST")
        openingHoursList.push({
          name: currentValue.name,
          value: currentValue.value
            ? formatHours("DASH", currentValue.value)
            : translate("RestaurantsPage.closed"),
        });

      if (
        feature === "CURRENT" &&
        currentValue.name.toString() === currentDayName
      )
        return `${translate(`App.day.${currentValue.name.toLowerCase()}`)}: ${
          currentValue.value
            ? formatHours("FROM-TO", currentValue.value)
            : translate("RestaurantsPage.closed")
        }`;
    }
    return openingHoursList;
  };

  const todayWorkingHours = (): OpeningHoursDay => {
    return {
      open: todaysOpeningHours(currentRestaurant.openingHours).value.split(
        "|"
      )[0],
      close: todaysOpeningHours(currentRestaurant.openingHours).value.split(
        "|"
      )[1],
    };
  };

  // FIXME: Quick fix for demo, change it
  const openHour = currentRestaurant
    ? (todayWorkingHours().open.split("|")[0] as string)
    : "00:00";
  const closeHour = currentRestaurant ? todayWorkingHours().close : "00:00";

  // Get the restaurant opening hour and create a new date depending on its current timezone
  const openingDate = (): DateTime => {
    const openingHour = openHour.split(":");
    return DateTime.local()
      .setZone(currentRestaurant.timeZone)
      .set({
        hour: parseInt(openingHour[0]),
        minute: parseInt(openingHour[1]),
      });
  };

  /**
   * calcul minDate
   * This hour should be inside of the opening hours of the restaurant (after open)
   * We should not allow the user to choose a time that is before the current time + order injection delay
   * @returns
   */

  // The min hour to get the order
  const minDate = () => {
    // Current hour in the restaurant's country
    const restaurantCurrentHour = currentDate();
    // Restaurant opening hour
    const openingHour = openingDate();

    const ordersDisabledUntil = DateTime.fromJSDate(new Date(currentRestaurant.settings.ordersEnabledAt)).setZone(currentRestaurant.timeZone);
    const nextPickupTime = ordersDisabledUntil && ordersDisabledUntil.valueOf() > currentDate().valueOf()
      ? DateTime.fromJSDate(new Date(currentRestaurant.settings.ordersEnabledAt)).setZone(currentRestaurant.timeZone)
      : restaurantCurrentHour;

    const minDate =
      openingHour.valueOf() < restaurantCurrentHour.valueOf()
        ? nextPickupTime
        : openingHour;

    return minDate.plus({
      minutes: currentRestaurant.settings.ordersInjectionDelay,
    });
  };

  /**
   * calcul maxDate
   * This hour should be inside of the opening hours of the restaurant (before close)
   * @returns
   */
  const maxDate = (): DateTime => {
    if (closeHour) {
      const timeMax = DateTime.local().setZone(currentRestaurant.timeZone);

      const close = closeHour.split(":");

      const hours = parseInt(close[0]);
      const minutes = parseInt(close[1]);
      const openHourSplitted = openHour.split(":")[0];

      const addidionalDay: 0 | 1 =
        +hours === 0 || +hours < +openHourSplitted ? 1 : 0;

      timeMax.set({ hour: hours, minute: minutes });

      return timeMax
        .plus({ days: addidionalDay })
        .set({ hour: hours, minute: minutes });
    }
    return currentDate();
  };

  const checkPickupTime = (): void => {
    const currDate = DateTime.local().setZone(currentRestaurant.timeZone).plus({
      minutes: currentRestaurant.settings.ordersInjectionDelay,
    });
    if (
      !pickUpTime ||
      DateTime.fromISO(pickUpTime).valueOf() < currDate.valueOf()
    ) {
      setPickUpTime(currDate);
    }
  };

  const isOpen = (restaurant: RestaurantObject): boolean => {
    const currentTime = DateTime.local();
    const openingHours = todaysOpeningHours(restaurant.openingHours).value;
    const [open, close] = openingHours.split("|");
    const [openHour, openMinute] = open.split(":");
    const [closeHour, closeMinute] = close.split(":");
    const openTimeStamp = DateTime.utc()
      .setZone(restaurant.timeZone)
      .set({ hour: openHour, minute: openMinute });
    const addidionalDay: 0 | 1 =
      +closeHour === 0 || +closeHour < +openHour ? 1 : 0;
    const closeTimeStamp = DateTime.utc()
      .setZone(restaurant.timeZone)
      .plus({ days: addidionalDay })
      .set({ hour: closeHour, minute: closeMinute });

    return (
      currentTime.valueOf() > openTimeStamp.valueOf() &&
      currentTime.valueOf() < closeTimeStamp.valueOf()
    );
  };

  return {
    handleOpeningHours,
    todayWorkingHours,
    isOpen,
    minDate,
    maxDate,
    checkPickupTime,
    openHour,
    closeHour,
  };
}

import {OutageType, ProductObject, RestaurantOutageProductsObject} from '@acrelec-cloud/apico-shared';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';

import { Filters } from 'src/components/Filters/Filters';
import { SearchEngine } from 'src/components/Products/SearchEngine/SearchEngine';
import {useStore} from 'src/contexts/store.context';

import {ProductItem} from './ProductItem/ProductItem';

export const ProductList = observer(() => {
	const {
		navigation: {filteredProducts, hasFilters},
    restaurant: {currentRestaurant},
	} = useStore();

	const [productToDisplay, setProductToDisplay] = useState<number | undefined>();

	return (
		<>
      <div className="header-product__list">
		    <div className="filters-container">
          {hasFilters && <Filters />}
        </div>
        <SearchEngine />
      </div>
			{filteredProducts && (
				<div className="product-list">
					{filteredProducts.map((item: ProductObject) => {
            const productOutage = 
              (currentRestaurant.outageProducts && 
                currentRestaurant.outageProducts.find((outage: RestaurantOutageProductsObject) => outage.productCode === item.code));
						return (
              (!productOutage || productOutage.state !== OutageType.DISABLED) && 
                <ProductItem key={item.code} item={item} productToDisplay={productToDisplay} setProductToDisplay={setProductToDisplay} />
            );
          })}
				</div>
			)}
		</>
	);
});

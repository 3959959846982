import { AuthType, AuthenticateUserResponse } from '@acrelec-cloud/apico-sdk';
import axios from 'axios';
import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import GoogleIcon from 'src/assets/images/icons/google.png';
import { useTranslate } from 'src/components/Languages/translate.hook';
import { useStore } from 'src/contexts/store.context';

interface AuthGoogleProps {
  onAuthFailed: () => void;
  onAuthSuccess: () => void;
}

export const AuthGoogle = ({onAuthFailed, onAuthSuccess}: AuthGoogleProps) => {
  const {translate} = useTranslate();
  
  const {
		auth: {authExternalUser},
		order: {emptyOrders},
	} = useStore();
  
  // (SSO) Auth by Google
  const {search} = useLocation();
  const accessTokenFromGoogle = new URLSearchParams(search).get('code');
  
  /* Fetch Google API from client side to get an Access Token */
  const getAccessToken = async (userCode: string) => {
    return await axios
      .post('https://oauth2.googleapis.com/token', {
        code: userCode,
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_OAUTH_SECRET_KEY,
        redirect_uri: process.env.REACT_APP_GOOGLE_OAUTH_REDIRECTION,
        grant_type: 'authorization_code',
      });
  }

  useEffect(() => {
    if(accessTokenFromGoogle) { // O'Auth from Google
      (async () => {
        const accessToken = await getAccessToken(accessTokenFromGoogle);
        if(accessToken.data.id_token) {
          authExternalUser({
            authType: AuthType.GOOGLE,
            accessToken: accessToken.data.id_token,
          })
            .then((user: AuthenticateUserResponse) => {
              if (user === undefined) throw new Error();
              onAuthSuccess();
            })
            .catch(() => onAuthFailed());
        }
      })();
    }
		return () => emptyOrders();
	});

  return (
    <a className="sso__button oauth__button" href={`https://accounts.google.com/o/oauth2/v2/auth?scope=email profile&access_type=offline&response_type=code&redirect_uri=${process.env.REACT_APP_GOOGLE_OAUTH_REDIRECTION}&client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}`}>
      <div className="sso__btn__icon">
        <img src={GoogleIcon} alt={translate(`Profile.sso_signup.google`)} />
      </div>
      <div className="sso__btn__label">
        <p className="txt-white">{translate(`Profile.sso_signup.google`)}</p>
      </div>
    </a>
  );
}

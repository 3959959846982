import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {MapComponent} from 'src/components/Restaurant/Map/MapComponent';
import {RestaurantList} from 'src/components/Restaurant/RestaurantList';
import {useStore} from 'src/contexts/store.context';
import {useRestaurantStatusChecks} from 'src/hooks/restaurant-status-checks.hook';
import {formatAddress} from 'src/utils/format-address';

import {LoadingSpinner} from '../components/LoadingSpinner/LoadingSpinner';

interface UserPositionProps {
	latitude: number;
	longitude: number;
}

export const RestaurantsPage = observer(() => {
	const {
		language: {currentLanguage},
	} = useStore();
	const {translate} = useTranslate();
	const history = useHistory();
	document.title = `${translate('RestaurantsPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;
	const {checkRestaurantStatus} = useRestaurantStatusChecks();
	const [restaurantToDisplayId, setRestaurantToDisplayId] = useState<undefined | number>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [distanceFormat, setDistanceFormat] = useState<string>();
	const [userLocation, setUserLocation] = useState<undefined | UserPositionProps>();

	const {
		restaurant: {
			fetchRestaurants,
			restaurants,
			setCurrentRestaurant,
			isUniqueRestaurant,
			currentRestaurant,
		},
	} = useStore();

	const onSelectRestaurant = (restaurantId: number) => {
		setRestaurantToDisplayId(restaurantId);
	};

	useEffect(() => {
		fetchRestaurants({lat: userLocation?.latitude, lng: userLocation?.longitude}).then(
			(restaurantList: RestaurantObject[]) => {
				setIsLoading(false);
				//If only ONE restaurant, Set the unique restaurant to current and go to orderPage
				if (isUniqueRestaurant) {
					const firstRestaurant = restaurantList[0];
					if (firstRestaurant.authenticationRequired && !currentRestaurant) {
						history.push(`/restaurants/${firstRestaurant.slug}/authenticate`);
					} else {
						setCurrentRestaurant(firstRestaurant);
						checkRestaurantStatus(firstRestaurant);
					}
				}
				if (!currentRestaurant && !restaurantToDisplayId && restaurants) {
					const firstRestaurant = restaurants[0];
					setRestaurantToDisplayId(firstRestaurant.id);
				}
			},
		);
		// eslint-disable-next-line
	}, [
		fetchRestaurants,
		restaurants,
		setRestaurantToDisplayId,
		restaurantToDisplayId,
		formatAddress,
		setCurrentRestaurant,
		userLocation,
	]);

	useEffect(() => {
		setTimeout(() => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
					const {latitude, longitude} = position.coords;
					setUserLocation({latitude, longitude});
				});
			}
		}, 1000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (currentLanguage === 'en') {
			setDistanceFormat('mi');
		} else {
			setDistanceFormat('km');
		}
	}, [currentLanguage]);

	return (
		<main className="restaurants-page-container">
			{isLoading ? (
				<div className="loading-div">
					<LoadingSpinner />
				</div>
			) : (
				<>
					<RestaurantList
						onSelect={onSelectRestaurant}
						userLocation={userLocation}
						distanceFormat={distanceFormat}
					/>
					<MapComponent
						restaurantToDisplayId={restaurantToDisplayId}
						userLocation={userLocation}
						distanceFormat={distanceFormat}
					/>
				</>
			)}
		</main>
	);
});

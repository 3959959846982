import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useCallback} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {ConfirmButton} from 'src/components/Restaurant/ConfirmButton';
import {RestaurantItem} from 'src/components/Restaurant/RestaurantItem';
import {SearchRestaurant} from 'src/components/Restaurant/SearchRestaurant';
import {useStore} from 'src/contexts/store.context';
import {scrollTo} from 'src/utils/scroll-to';

export interface RestaurantListProps {
  onSelect: (restaurantId: number) => void;
  userLocation: any;
  distanceFormat: any;
}

export const RestaurantList = observer(({onSelect, userLocation, distanceFormat}: RestaurantListProps) => {
	const {translate} = useTranslate();
	const {
		restaurant: {currentRestaurant, isUniqueRestaurant, restaurants},
	} = useStore();

	const onDisplayRestaurant = useCallback(
		(event, restaurantId) => {
			const clickedElement = event.target as HTMLElement;
			const itsNotSeeMore = !clickedElement.classList.contains(
				'restaurant-item__see-more',
			);
			if (itsNotSeeMore) {
				if (onSelect) {
					onSelect(restaurantId);
				}
				scrollTo('top');
			}
		},
		[onSelect]
	)

	return (
		<div
			className={`restaurants__container${
				currentRestaurant ? ' restaurants__container--sticky-button' : ''
			}`}>
			{!isUniqueRestaurant && <SearchRestaurant />}
			<div className="restaurants__content">
				<ul className="restaurants__list">
					{restaurants && restaurants.length > 0 ? (
						restaurants.map((restaurant: RestaurantObject, index: number) => (
							<div key={index} aria-label="Select">
								<RestaurantItem
									onDisplayRestaurant={(e: Event | React.MouseEvent) =>
										onDisplayRestaurant(e, restaurant.id)
									}
									key={restaurant.id}
									restaurant={restaurant}
									userLocation={userLocation}
									distanceFormat={distanceFormat}
								/>
							</div>
						))
					) : (
						<li className="restaurant__item">
							<p className="restaurant-item__text">{translate('RestaurantsPage.noRestaurant')}</p>
						</li>
					)}
				</ul>
			</div>
			{restaurants && restaurants.length > 0 && (
				<div
					className={`restaurants__action${
						currentRestaurant ? ' restaurants__action--sticky' : ''
					}`}>
					<ConfirmButton />
				</div>
			)}
		</div>
	);
});

import {OpeningHours} from '@acrelec-cloud/apico-cdk';
import {RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';
import {useOpeningHours} from 'src/hooks/opening-hours.hook';
import {formatAddress} from 'src/utils/format-address';

import {handleDistance} from './distance.util';
import {PickRestaurantButton} from './PickRestaurantButton';

export enum PickRestaurantModal {
	NULL,
	UNAVAILABLE_RESTAURANT = 'UNAVAILABLE_RESTAURANT',
	RESET_BASKET = 'RESET_BASKET',
}

interface RestaurantItemProps {
	restaurant: RestaurantObject;
	onDisplayRestaurant: (e: Event | React.MouseEvent, restaurantId: number) => void;
	userLocation: any;
	distanceFormat: any;
}

export const RestaurantItem = observer(({restaurant, onDisplayRestaurant, userLocation, distanceFormat}: RestaurantItemProps) => {
	const {
		address,
		geolocation,
		information,
		name,
		openingHours,
		id: restaurantId,
		settings: {ordersEnabled},
	} = restaurant;

	const {translate} = useTranslate();
	const {handleOpeningHours, isOpen} = useOpeningHours();
	const history = useHistory();
	const openInfo = new URLSearchParams(useLocation().search).get('openInfo');
	const displayOpeningHoursEndRef = useRef<null | HTMLDivElement>(null);
	const [restaurantIsOpen, setRestaurantIsOpen] = useState<boolean>();

	const {
		restaurant: {currentRestaurant, isUniqueRestaurant},
  } = useStore();
  
	// SetInterval is needed to know if restaurant status is open
	useEffect(() => {
		setRestaurantIsOpen(isOpen(restaurant));
		const updateRestaurantStatus = setInterval(() => {
			const open = isOpen(restaurant);
			setRestaurantIsOpen(open);
		}, 3000);
		return () => clearInterval(updateRestaurantStatus);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isOpeningHours = () => {
		return openInfo && restaurantId === +openInfo;
	};

	const displayOpeningHours = () => {
		const destiny = isOpeningHours() ? '/restaurants' : `/restaurants?openInfo=${restaurantId}`;
		history.push(destiny);
	};

	const restaurantActiveClass = useMemo(() => {
		return !!currentRestaurant && currentRestaurant.id === restaurantId
			? ' restaurant-item--active'
			: '';
	}, [currentRestaurant, restaurantId]);

	return (
		<li className={`restaurant-item${restaurantActiveClass}`}>
			{!ordersEnabled && (
				<p className="txt-error txt-right txt-s">{translate('RestaurantItem.ordering_disabled')}</p>
			)}
			{!restaurantIsOpen && (
				<div className="restaurant-item__tag restaurant-item__tag--closed txt-bold txt-white bg-error">
					{translate('RestaurantItem.closed')}
				</div>
			)}
			<div className="restaurant-item__container">
				<div className="restaurant-item__main-info" onClick={onDisplayRestaurant}>
					<p className="txt-bold">
						{name}
						{(userLocation &&
							distanceFormat &&
                restaurant.geolocation.longitude && restaurant.geolocation.latitude) &&
                  ` - ${handleDistance(userLocation, geolocation, distanceFormat)}`}
					</p>
					<p>
						{translate(`RestaurantItem.${restaurantIsOpen ? 'open' : 'closed'}`)}
						{' - '}
						{handleOpeningHours('CURRENT', openingHours) as string}
					</p>
				</div>
				{/* If there is only one restraunt hide the button to show and hide restaurant details */}
				{!isUniqueRestaurant && (
					<div className="restaurant-item__actions">
						<button
							className={`btn__secondary--mini-inverse btn__secondary--icon-after btn__secondary--icon-fas restaurant-item__see-more${
								isOpeningHours() ? ' restaurant-item__see-more--open' : ''
							}`}
							theme-icon=""
							onClick={() => displayOpeningHours()}>
							{translate(`RestaurantItem.hours`) + ' & ' + translate(`RestaurantItem.infos`)}
						</button>
						<PickRestaurantButton
							restaurantInfo={restaurant}
							onDisplayRestaurant={onDisplayRestaurant}
						/>
					</div>
				)}
				{/* Display opening hours if there is only one restaurant of if the button to display details hab been clicked */}
				{(isUniqueRestaurant || isOpeningHours()) && (
					<div className="restaurant-item__details" ref={displayOpeningHoursEndRef}>
						{address.city && address.zipCode && address.street && (
							<div className="restaurant-item__address">
								<p className="txt-bold txt-primary">{translate(`RestaurantItem.address`)}</p>
								<p className="txt-secondary">{formatAddress(address)}</p>
							</div>
						)}
						<ul className={`restaurant-item__hours ${information ? 'border-bottom' : ''}`}>
							<p className="txt-bold txt-primary">{translate(`RestaurantItem.hours`)}</p>
							{(handleOpeningHours('LIST', openingHours) as OpeningHours[]).map(
								(item: OpeningHours, index: number) => (
									<li className="restaurant-item__hours-list txt-grey-50" key={index}>
										<p className="restaurant-item__hours-list-day txt-bold">
											{translate(`App.day.${item.name.toLowerCase()}`)}
										</p>
										<p className="restaurant-item__hours-list-time">{item.value}</p>
									</li>
								),
							)}
						</ul>
						{information && (
							<div className="restaurant-item__infos">
								<p className="txt-bold txt-primary">{translate(`RestaurantItem.infos`)}</p>
								<p>{information}</p>
							</div>
						)}
					</div>
				)}
			</div>
		</li>
	);
});

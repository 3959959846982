import {OrderObject, OrderState, RestaurantObject} from '@acrelec-cloud/apico-sdk';
import {DateTime} from 'luxon';
import {observer} from 'mobx-react-lite';
import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router';
import {ReactSVG} from 'react-svg';

import shop from 'src/assets/images/icons/shop.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {CheckinOrderModal} from 'src/components/Modal/CustomModals/CheckinOrderModal';
import {CurrentOrderModal} from 'src/components/Modal/CustomModals/DeleteOrderModal/CurrentOrderModal';
import {OrderRatingModal} from 'src/components/Modal/CustomModals/OrderRatingModal';
import {OrderEstimated} from 'src/components/Order/OrderEstimated';
import {OrderRating} from 'src/components/Order/OrderRating';
import {OrderRestaurantInfo} from 'src/components/Order/OrderRestaurantInfo';
import {SumOrderList} from 'src/components/Order/SumBasket/SumOrderList';
import {useStore} from 'src/contexts/store.context';
import {useEasyDateHour} from 'src/hooks/easy-date-hour.hook';
import {formatAddress} from 'src/utils/format-address';

import {OrderWaiting} from './OrderWaiting';
import {ReorderButton} from './ReorderButton';

export enum OrderContainerModal {
	NULL,
	CANCEL_ORDER = 'CANCEL_ORDER',
	CANCEL_FAIL = 'CANCEL_FAIL',
	CANCEL_SUCCESS = 'CANCEL_SUCCESS',
}
interface OrderContainerProps {
	order: OrderObject;
	cancellation?: boolean;
	isPickUpModification?: boolean;
}
export const OrderContainer = observer(
	({order, cancellation = false, isPickUpModification}: OrderContainerProps) => {
		const {createdAt, restaurantId, posId, state, retrievalDate, items, id} = order;
		const {translate} = useTranslate();
		const location = useLocation();
		const [restaurant, setRestaurant] = useState<undefined | RestaurantObject>();
		const {defaultDateHour} = useEasyDateHour();
		const [modalStatus, setModalStatus] = useState<OrderContainerModal>(OrderContainerModal.NULL);
		const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
		const [isRatingSuccess, setIsRatingSuccess] = useState<boolean>(false);
		const [lastRating, setLastRating] = useState<number>(0);
		const [openDetails, setOpenDetails] = useState(false);
		const [orderItems, setOrderItems] = useState([]);

		const onSetLastRating = (index: React.SetStateAction<number>) => {
			setLastRating(index);
		};

		const {
			restaurant: {fetchRestaurant},
			order: {fetchOrderItems},
		} = useStore();

		const pickupTime = retrievalDate.toString();

		const isFeedbacked = useMemo(() => {
			if (order.feedbackedAt) {
				return true;
			}
		}, [order]);

		useEffect(() => {
			fetchRestaurant(restaurantId)
				.then((restaurant: React.SetStateAction<RestaurantObject | undefined>) => {
					setRestaurant(restaurant);
				})
				.catch(() => setRestaurant(undefined));
		}, [order, fetchRestaurant, restaurantId]);

		useEffect(() => {
			if (!modalStatus) document.body.classList.remove('modal-show');
		}, [modalStatus]);

		const isOrderHistoryPage = useMemo(() => {
			return location.pathname === '/order-history';
		}, [location]);

		const showOrderDetails = () => {
			setOpenDetails(!openDetails);
			!openDetails &&
				fetchOrderItems(order.id)
					.then((response: any) => setOrderItems(response))
					.catch((error: any) => console.error(error));
		};

		const orderHeaderRight = useMemo((): ReactNode => {
			const posIdString = posId?.toString();
			const lastDigitsPosId = posIdString?.slice(-2);
			const currentDate: Date = new Date();
			const retrievalDateRaw: Date = new Date(retrievalDate);
			const retrievalDateWithDelay: Date = restaurant
				? new Date(retrievalDateRaw.getTime() - restaurant.settings.ordersInjectionDelay * 60000)
				: retrievalDateRaw;

			return !posId ? (
				state === OrderState.VALIDATED &&
					+currentDate < +retrievalDateWithDelay &&
					!isPickUpModification &&
					!cancellation && (
						<button
							className="order-container__button txt-xs txt-black"
							onClick={() => {
								setModalStatus(OrderContainerModal.CANCEL_ORDER);
							}}>
							{translate('OrderContainer.cancel_order')}
						</button>
					)
			) : (
				<p className="order-container__title--right txt-m txt-bold">
					{translate(`OrderContainer.number`)}
					<span>{lastDigitsPosId}</span>
					<span className="txt-s txt-regular"> ({posId})</span>
				</p>
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [posId, state, translate]);

		const displayCheckinButton = useMemo(() => {
			if (
				!restaurant ||
				order.state === OrderState.INJECTION_ERROR ||
				order.state === OrderState.PAYMENT_ERROR ||
				order.state === OrderState.CANCELLED ||
				!!order.posId
			) {
				return false;
			}
			const currentDate = DateTime.now().setZone(restaurant.timeZone);
			const preparationDate = DateTime.fromISO(retrievalDate.toString())
				.minus({minutes: restaurant.settings.ordersInjectionDelay})
				.setZone(restaurant.timeZone);
			return currentDate.valueOf() < preparationDate.valueOf();
		}, [restaurant, retrievalDate, order]);

		return (
			<div className="order-container">
				{!isOrderHistoryPage && state === OrderState.CREATED && <OrderWaiting />}
				{isOrderHistoryPage && (state === OrderState.PAID || state === OrderState.SENT) && (
					<OrderRating
						order={order}
						setIsRatingSuccess={() => setIsRatingSuccess(true)}
						onSetLastRating={onSetLastRating}
					/>
				)}

				{isFeedbacked && (
					<div className="order-container__test">
						<p className="order-container__reported">{translate('OrderContainer.feedback_sent')}</p>
					</div>
				)}

				<div className="order-container__title">
					<p className="order-container__title--left txt-xs">
						{defaultDateHour(
							new Date(createdAt),
							restaurant ? restaurant.timeZone : DateTime.local().zoneName,
						)}
					</p>
					{orderHeaderRight}
				</div>

				{restaurant && (
					<>
						<div className="order-container__restaurant">
							<div className="order-container__restaurant-informations">
								<ReactSVG className="order-container__icon" src={shop} />
								<div className="order-container__details">
									<div className="order-container__paragraph">
										<p className="txt-bold txt-xs">{restaurant.name}</p>
										<p className="txt-xs">{formatAddress(restaurant.address)}</p>
									</div>
								</div>
							</div>
							{isOrderHistoryPage && <ReorderButton order={order} />}
						</div>
						{!isOrderHistoryPage && (
							<div className="order-container__checkin">
								<OrderRestaurantInfo
                  pickUpTime={pickupTime}
                  posId={posId}
                  restaurant={restaurant}
                  orderState={order.state}
                  orderInjectionTime={order.injectedAt && order.injectedAt.toString()}
                  ordersInjectionDelay={
                    restaurant.settings.ordersInjectionDelay
                } />
								{displayCheckinButton && !cancellation && order.state !== OrderState.CONFLICTED && (
									<div className="checkin-div">
										<p className="txt-xs">
											{translate('OrderContainer.update_order_first')}
											<button
												className="order-container__button txt-bold txt-xs"
												onClick={() => {
													setModalIsOpen(!modalIsOpen);
												}}>
												{translate('OrderContainer.update_order_button')}
											</button>
											{translate('OrderContainer.update_order_second')}
										</p>
									</div>
								)}
							</div>
						)}
					</>
				)}
				{order.state !== OrderState.CONFLICTED && restaurant && (
					<OrderEstimated
						order={order}
						pickUpTime={pickupTime}
						restaurant={restaurant}
						ordersInjectionDelay={restaurant.settings.ordersInjectionDelay}
					/>
				)}

				{items && restaurant && (
					<div
						className={
							openDetails ? 'order-container__orders show' : 'order-container__orders hide'
						}>
						{orderItems.length === 0 ? (
							<div className="order-items__loading-container">
								<LoadingSpinner height={'20px'} width={'20px'} />
							</div>
						) : (
							<SumOrderList
								order={order}
								orderItems={orderItems}
								open={openDetails}
								currency={restaurant.currency}
							/>
						)}
					</div>
				)}
				<div className="current-order__actions txt-center">
          {order.state === OrderState.CONFLICTED && (
            <a href="/order" className="order-btn__action btn__secondary btn__order-again">
              {translate('CurrentOrderPage.order_again')}
            </a>
          )}
					<button
						onClick={showOrderDetails}
						className={
							openDetails
								? 'order-btn__action current-order__see__more see__more-bckg__black'
								: 'order-btn__action current-order__see__more see__more-bckg__white'
						}>
						{openDetails ? translate('OrderPage.hide_details') : translate('OrderPage.see_details')}
					</button>
				</div>
				{cancellation && (
					<div className="current-order__actions txt-center">
						<button
							className="current-order__button btn__primary current-order__cancel__button"
							onClick={() => {
								setModalStatus(OrderContainerModal.CANCEL_ORDER);
							}}>
							{translate(`CancelOrderPage.button.cancel`)}
						</button>
					</div>
				)}
				<CurrentOrderModal
					orderId={id}
					status={modalStatus}
					setStatus={(status: OrderContainerModal) => setModalStatus(status)}
				/>
				<CheckinOrderModal open={modalIsOpen} onClose={() => setModalIsOpen(false)} order={order} />
				<OrderRatingModal
					open={isRatingSuccess}
					onClose={() => {
						setIsRatingSuccess(false);
						onSetLastRating(0);
					}}
					order={order}
					lastRating={lastRating}
					onSetLastRating={onSetLastRating}
				/>
			</div>
		);
	},
);

/* eslint-disable jsx-a11y/anchor-is-valid */
import {AuthType, AuthenticateUserResponse} from '@acrelec-cloud/apico-sdk';
import React from 'react';

import FacebookIcon from 'src/assets/images/icons/facebook.png';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

interface AuthGoogleProps {
	onAuthFailed: () => void;
	onAuthSuccess: () => void;
}

export const AuthFacebook = ({onAuthFailed, onAuthSuccess}: AuthGoogleProps) => {
	const {translate} = useTranslate();

	const {
		auth: {authExternalUser},
		language: {currentLanguage},
	} = useStore();

	window.fbAsyncInit = function () {
		window.FB.init({
			appId: Number(process.env.REACT_APP_FACEBOOK_OAUTH_APP_ID),
			cookie: true,
			xfbml: true,
			version: process.env.REACT_APP_FACEBOOK_OAUTH_VERSION,
		});
	};

	const signWithFacebook = () => {
		FB.login(function(response: any) {
		  if(response && response.status === 'connected' && response.authResponse.accessToken) {
		    const accessTokenFromFacebook = response.authResponse.accessToken;
		    return authExternalUser({
		      authType: AuthType.FACEBOOK,
		      accessToken: accessTokenFromFacebook,
		      language: currentLanguage,
		    })
		    .then((user: AuthenticateUserResponse) => {
		      if (user === undefined) throw new Error();
		      onAuthSuccess();
		    })
		    .catch(() => onAuthFailed());
		  }
		  else return onAuthFailed();
		}, {scope: 'email, public_profile'});
	};

	// load facebook sdk script
	(function (d, s, id) {
		var js,
			fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.src = 'https://connect.facebook.net/en_US/sdk.js';
		fjs.parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');

	return (
		<a className="sso__button fb__button" href="#" onClick={() => signWithFacebook()}>
			<div className="sso__btn__icon">
				<img src={FacebookIcon} alt={translate(`Profile.sso_signup.facebook`)} />
			</div>
			<div className="sso__btn__label">
				<p className="txt-white">{translate(`Profile.sso_signup.facebook`)}</p>
			</div>
		</a>
	);
};

import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

export const LoadingMap = observer(() => {
	const {translate} = useTranslate();

	return <p>{translate('RestaurantsPage.map.loading')}</p>;
});

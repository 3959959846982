import {ProductObject, RestaurantOutageProductsObject} from '@acrelec-cloud/apico-sdk';
import {includes} from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import {isMobile} from 'react-device-detect';
import {useHistory} from 'react-router';

import {useStore} from 'src/contexts/store.context';

import {ProductChoice} from './ProductChoice';
import {ProductTile} from './ProductTile';

interface ProductItemProps {
	item: ProductObject;
	productToDisplay: number | undefined;
	setProductToDisplay: (code: number) => void;
}
export const ProductItem = observer((props: ProductItemProps) => {
	const {item, productToDisplay, setProductToDisplay} = props;
	const {dimensions, type} = item;
	const [displayChoiceTile, setDisplayChoiceTile] = useState(false);
	const history = useHistory();

	const isCombo = includes(type, 'COMBO');
	const hasDimensions = dimensions.length > 0;
	const meal = hasDimensions && dimensions.find((dimension) => dimension.dimensionChar === 'M');
	const shouldDisplaySelect = !isCombo && hasDimensions && !!meal;

  const {
    restaurant: {currentRestaurant},
	} = useStore();

  const productUnavailable = 
    (currentRestaurant.outageProducts && 
      currentRestaurant.outageProducts.find((outage: RestaurantOutageProductsObject) => outage.productCode === item.code));

	const handleProductAction = useMemo(() => {
    if(productUnavailable) return false; // This product is unavailable
		const onDisplaySelect = () => {
			setDisplayChoiceTile(true);
			setProductToDisplay(item.code);
		};
		const onNavigate = () => history.push(`/product/${item.code}`);
		return isMobile
			? {
					onClick: () => (shouldDisplaySelect ? onDisplaySelect() : onNavigate()),
			  }
			: {
					onMouseEnter: () => onDisplaySelect(),
					onClick: () => (shouldDisplaySelect ? undefined : onNavigate()),
			  };
	}, [history, item.code, productUnavailable, setProductToDisplay, shouldDisplaySelect]);

	useEffect(() => {
		if (productToDisplay !== item.code) {
			setDisplayChoiceTile(false);
		}
	}, [productToDisplay, setDisplayChoiceTile, item]);

	return (
		<div className="product-item hover-parent" {...handleProductAction}>
			{shouldDisplaySelect && displayChoiceTile && meal && (
				<div className="hover-child">
					<ProductChoice mealCode={meal.productCode} itemCode={item.code} />
				</div>
			)}
			<ProductTile item={item} />
		</div>
	);
});

import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

export const ConfirmButton = observer(() => {
	const {
		restaurant: {currentRestaurant, isUniqueRestaurant},
	} = useStore();

	const {translate} = useTranslate();

	return (
		<Link className="txt-extrabold confirm-link" to="/order">
			<button disabled={!currentRestaurant && !isUniqueRestaurant} className={`btn__primary`}>
				{translate(`ConfirmButton.btn_next`)}
			</button>
		</Link>
	);
});

import {IdParam} from '@acrelec-cloud/apico-cdk';
import {observer} from 'mobx-react-lite';
import React, {useMemo, useEffect, useState} from 'react';
import {Redirect, useHistory, useParams} from 'react-router-dom';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {useCategoryNavigation} from 'src/components/Products/Navigation/category-navigation.hook';
import {ProductList} from 'src/components/Products/Product/ProductList';
import {useStore} from 'src/contexts/store.context';
import {usePageFound} from 'src/hooks/page-found.hooks';

import {LoadingSpinner} from '../components/LoadingSpinner/LoadingSpinner';

export const OrderPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate(`OrderPage.title`)} | ${process.env.REACT_APP_BRAND_NAME}`;

	const [pageFound] = usePageFound();
	const history = useHistory();
	const {id} = useParams<IdParam>();
	const categoryNavigation = useCategoryNavigation();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const {
		navigation: {
			currentNavigation,
			resetAllFilters,
			navigation,
			fetchNavigation,
			setCurrentNavigation,
			fetchNavigationProducts,
		},
	} = useStore();

	const idParam = useMemo(() => id ? Number(id) : navigation && navigation.length > 0 ? navigation[0].id : 0, [id, navigation]);

	useEffect(() => {
		fetchNavigation().catch(() => history.replace('404'));
	}, [fetchNavigation, history]);

	useEffect(() => {
		if (idParam) {
			setIsLoading(true);
			if (currentNavigation !== idParam) {
				resetAllFilters();
			}
			setCurrentNavigation(idParam);
			pageFound('CATEGORY', idParam) && fetchNavigationProducts(idParam);
			fetchNavigationProducts(idParam).then(() => {
				setIsLoading(false);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigation, idParam]);

	return (
		<main className="order-page">
			{isLoading ? (
				<div className="loading-div">
					<LoadingSpinner />
				</div>
			) : (
				<div className="order-page__container theme-container">
					{pageFound('CATEGORY', idParam) ? (
						<>
							{categoryNavigation}
							<ProductList />
						</>
					) : (
						<Redirect to="/404" />
					)}
				</div>
			)}
		</main>
	);
});

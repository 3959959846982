import axios from "axios";

import { GeolocationModel } from "src/components/Restaurant/distance.util";
import {
  gMap,
  GoogleMapsGeolocation,
} from "src/components/Restaurant/Map/map.config";
import { useStore } from "src/contexts/store.context";

import { useUserLocation } from "./user-location.hook";

interface gLocation {
  name?: string;
  lat: number;
  lng: number;
}

export interface LocationByAddress {
  fullAddress: string;
  geolocation: gLocation;
}

export interface UseGeolocation {
  locationByAddress: (address: string) => Promise<LocationByAddress>;
  cancelLocationByAddress: any;
  checkLocation: (lat: number, lng: number) => boolean;
  initialLocation: (lat: number, lng: number) => GoogleMapsGeolocation;
  defineMapLocation: () => GoogleMapsGeolocation;
  countryByCoordinates: (userLocation: GeolocationModel) => any;
}

export const useGeolocation = (): UseGeolocation => {
  const {
    restaurant: { restaurants, currentRestaurant, restaurantToDisplayId },
  } = useStore();

  const { userLocation } = useUserLocation();

  // Cancel axios request ----------------------------------
  const CancelToken = axios.CancelToken;
  let cancelLocationByAddress: any;
  // -------------------------------------------------------

  const locationByAddress = async (
    address: string
  ): Promise<LocationByAddress> => {
    if (cancelLocationByAddress) {
      //Before doing a new request, cancel the previous request whether still running
      cancelLocationByAddress();
    }

    const googleKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleKey}`;
    const GoogleApiResult = await axios
      .get(geocodeUrl, {
        // It's allow you to cancel this request in the future is needed
        cancelToken: new CancelToken(function executor(cancel) {
          cancelLocationByAddress = cancel;
        }),
      })
      .then(
        ({ data }) => data.status === "OK" && data.results[0] && data.results[0]
      );

    const result = GoogleApiResult
      ? {
          fullAddress: GoogleApiResult.formatted_address,
          geolocation: GoogleApiResult.geometry.location,
        }
      : Promise.reject("No results for this query");

    return result;
  };

  const countryByCoordinates = async ({
    latitude,
    longitude,
  }: GeolocationModel) => {
    const googleKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY!;
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&result_type=country&key=${googleKey}`;
    const GoogleApiResult = await axios
      .get(geocodeUrl)
      .then(
        ({ data }) => data.status === "OK" && data.results[0] && data.results[0]
      );

    const result = GoogleApiResult
      ? GoogleApiResult.formatted_address
      : Promise.reject("No results for this query");

    return result;
  };

  const checkLocation = (lat: number, lng: number): boolean => {
    if (lat && lat !== 0 && lng && lng !== 0) return true;
    return false;
  };

  const initialLocation = (lat: number, lng: number): GoogleMapsGeolocation => {
    const validLocation = checkLocation(lat, lng);
    const defaultLocation = userLocation
      ? { lat: userLocation.latitude, lng: userLocation.longitude }
      : gMap.defaultLocation;

    const location = validLocation ? { lat, lng } : defaultLocation;

    return location;
  };

  const defineMapLocation = (): GoogleMapsGeolocation => {
    if (currentRestaurant && !restaurantToDisplayId) {
      const latitude = currentRestaurant.geolocation.latitude || 0;
      const longitude = currentRestaurant.geolocation.longitude || 0;
      return initialLocation(latitude, longitude);
    }

    const firstRestaurant = restaurants?.find((restaurant) =>
      checkLocation(
        restaurant.geolocation.latitude || 0,
        restaurant.geolocation.longitude || 0
      )
    );

    const latitude = firstRestaurant ? firstRestaurant.geolocation.latitude : 0;
    const longitude = firstRestaurant
      ? firstRestaurant.geolocation.longitude
      : 0;

    return initialLocation(latitude, longitude);
  };

  return {
    locationByAddress,
    cancelLocationByAddress,
    checkLocation,
    initialLocation,
    defineMapLocation,
    countryByCoordinates,
  };
};

import {BasketProductModelType, RequiredPartsType} from '@acrelec-cloud/apico-cdk';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import {getSnapshot} from 'mobx-state-tree';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {ComponentNameTypes, PopoverTypes} from 'src/components/Popover/custom-popovers.config';
import {usePopover} from 'src/components/Popover/popover.hook';
import {
	BubbleMessage,
	useBubbleMessage,
} from 'src/components/Products/AddToCart/bubble-message.hook';
import {useStore} from 'src/contexts/store.context';
import {useMemoCompare} from 'src/hooks/memo-compare.hook';
import { useOpeningHours } from 'src/hooks/opening-hours.hook';
import {rootStore} from 'src/stores/root.store';

export enum ActionType {
  UPDATE = 'UPDATE',
  ADD = 'ADD',
};

export interface AddToCartButtonProps {
	code: number;
	quantity: number;
	wide?: boolean;
  action: ActionType;
  basketId?: string | null;
}

export const AddToCartButton = observer((props: AddToCartButtonProps) => {
	const {wide, action, basketId} = props;
	const {translate} = useTranslate();
	const [disabled, setDisabled] = useState<boolean>(true);
	const {openPopover} = usePopover();
	const {isOpen} = useOpeningHours();

	const {
		basket: {addBasketProduct, updateProduct},
		customization: {required},
		restaurant: {orderingDisabled, currentRestaurant},
	} = useStore();

	const restaurantIsOpen = useMemo(
		() => currentRestaurant && isOpen(currentRestaurant),
		[currentRestaurant, isOpen],
	);

	const requiredParts = useCallback(
		() => required.filter((part: RequiredPartsType) => part.partRequired),
		[required],
	);

	const {warningType, translatedMessage, bubbleMessage, setBubbleMessage} = useBubbleMessage({
		restaurantIsOpen,
		orderingDisabled,
		hasRequiredParts: requiredParts().length > 0,
	});

	const comparedRequired = useMemoCompare(
		() => toJS(required),
		required,
		(a: RequiredPartsType[], b: RequiredPartsType[]) =>
			a.length === b.length &&
			a.every((item: RequiredPartsType, index: number) => {
				const otherItem = b[index];
				return item.qty === otherItem.qty;
			}),
	);

	useEffect(() => {
		setDisabled(true);
		const timer = setTimeout(() => {
			if (!restaurantIsOpen) {
				setDisabled(true);
			} else {
				setDisabled(requiredParts().length > 0 ? true : false);
			}
		}, 500);
		return () => clearTimeout(timer);
	}, [
		comparedRequired,
		setDisabled,
		orderingDisabled,
		currentRestaurant,
		restaurantIsOpen,
		requiredParts,
	]);

	useEffect(() => {
		if (orderingDisabled) {
			setDisabled(true);
		}
	}, [setDisabled, orderingDisabled]);

	const onAdd = useCallback(() => {
    const currentCustomization: BasketProductModelType = getSnapshot(rootStore['customization']);
    if(action === ActionType.ADD) {
      const maxQtyProduct = 99999; // Unlimited quantity
      addBasketProduct(currentCustomization, maxQtyProduct)
    } else {
      updateProduct(basketId, currentCustomization);
    }
    openPopover(PopoverTypes.RIGHT, ComponentNameTypes.Basket);
	}, [action, addBasketProduct, basketId, openPopover, updateProduct]);

	return (
		<div
			className="add-cart"
			{...(disabled && {
				onMouseEnter: () => setBubbleMessage(warningType()),
				onMouseLeave: () => setBubbleMessage(BubbleMessage.NULL),
			})}>
			{disabled ? (
				<>
					<div
						className={`add-cart__button add-cart__button--disabled btn__primary btn__primary--icon-before btn__primary--icon-fas${
							!wide ? ' btn-padding' : ''
						}`}
						theme-icon="">
						{translate(`App.${action === ActionType.ADD ? 'add_cart_btn' : 'edit_cart_btn'}`)}
					</div>
					{!!bubbleMessage && (
						<div className="add-cart__warning">{translatedMessage[bubbleMessage]}</div>
					)}
				</>
			) : (
				<button
					className={`add-cart__button btn__primary btn__primary--icon-before btn__primary--icon-fas${
						!wide ? ' btn-padding' : ''
					}`}
					theme-icon=""
					disabled={disabled}
					onClick={() => onAdd()}>
					{translate(`App.${action === ActionType.ADD ? 'add_cart_btn' : 'edit_cart_btn'}`)}
				</button>
			)}
		</div>
	);
});

import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {ProductActions} from 'src/components/Products/Product/ProductActions';
import {useStore} from 'src/contexts/store.context';
import {useImage} from 'src/hooks/image.hook';

export const ProductInfo = observer(() => {
	const {translate, translateProduct} = useTranslate();

	const {
		customization: {currentProduct},
	} = useStore();

	const {image} = useImage(currentProduct.imageUrl);

	return (
		<div className="product-info">
			{currentProduct && (
				<div className="product-info__container">
					{currentProduct.imageUrl && (
						<figure className="product-info__figure">
							<img src={image} alt={currentProduct.name} className="product-info__img" />
						</figure>
					)}
					<div className="product-info__content">
						<h1 className="product-info__name txt-h1">{translateProduct(currentProduct).name}</h1>
						<p className="product-info__description txt-s txt-italic txt-secondary-dark">
							{translateProduct(currentProduct).description}
						</p>
						{!!currentProduct.calories && (
							<p className="product-info__calories txt-xs text-uppercase txt-black">{`${
								currentProduct.calories
							} ${translate(`App.calories`)}`}</p>
						)}
						<ProductActions />
					</div>
				</div>
			)}
		</div>
	);
});

// import {ProductObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useMemo} from 'react';

import {DiscountComponent} from 'src/components/Discount/DiscountComponent';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {CheckoutFormNepting} from 'src/components/Payment/Nepting/CheckoutFormNepting';
import {CheckoutFormStripe} from 'src/components/Payment/Stripe/CheckoutFormStripe';
import {useStore} from 'src/contexts/store.context';

import {SelectHourComponent} from '../PickUp/SelectHourComponent';

export const CheckoutPageInfos = observer(() => {
	const {translate} = useTranslate();
	const {
		basket: {setPickUpTime},
	} = useStore();

	const checkoutForm = useMemo(() => {
		return process.env.REACT_APP_PAYMENT_SYSTEM === 'NEPTING' ? (
			<CheckoutFormNepting />
		) : (
			<CheckoutFormStripe />
		);
	}, []);

	return (
		<div className="checkout-page-left">
			<div className="checkout-page-left__content-wrapper select-hour__wrapper content-wrapper--padding">
				<SelectHourComponent
					title={translate('SelectHourComponent.title')}
					callback={setPickUpTime}
				/>
			</div>
			<DiscountComponent />
			{checkoutForm}
		</div>
	);
});

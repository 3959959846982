import {observer} from 'mobx-react-lite';
import React from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';

// import privacyNotice from './theoutpost-privacy-notice.json';
// import legals from './theoutpost-terms-of-use.json';

// The observer is necessary here for the translation
export const LegalsPage = observer(() => {
	const {translate} = useTranslate();
	document.title = `${translate('LegalsPage.title')} | ${process.env.REACT_APP_BRAND_NAME}`;

	return (
		<main className="legals">
			<div className="legals__container theme-container">
				<h1 className="legals__title">{translate('LegalsPage.title')}</h1>
				<div className="legals__body">
					{/* <div>
						<p className="txt-bold legals__text">
							PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE. ALL USERS OF
							THIS SITE AGREE THAT ACCESS TO AND USE OF THIS SITE ARE SUBJECT TO THESE TERMS AND
							CONDITIONS AND APPLICABLE LAWS. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS,
							PLEASE DO NOT USE THIS SITE.
						</p>
					</div>
					{legals.map((legal, index) => {
						const {title, text} = legal;
						return (
							<div key={index} className="legals__body--section">
								{title && <h2 className="legals__subtitle">{title}</h2>}
								<p className="legals__text">{text}</p>
							</div>
						);
					})}
					<h2 className="legals__title">PRIVACY NOTICE</h2>
					{privacyNotice.map((notice, index) => {
						const {title, text} = notice;
						return (
							<div key={index} className="legals__body--section">
								{title && <h2 className="legals__subtitle">{title}</h2>}
								<p className="legals__text">{text}</p>
							</div>
						);
					})} */}
					<div className="legals__body--section">
						<h2 className="legals__subtitle">Cookies</h2>
						<div id="cookiefirst-policy-page"></div>
					</div>
				</div>
			</div>
		</main>
	);
});

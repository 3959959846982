import {ProductObject, ProductPartObject, ProductType} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {usePartComparator} from 'src/components/Products/Parts/hooks/part-comparator.hook';
import {ToggleButton} from 'src/components/Products/Parts/ToggleButton';
import {calculatePartPrice} from 'src/components/Products/Parts/utils/calculate-part-price.utils';
import {findCurrentPartByParent} from 'src/components/Products/Parts/utils/find-current-part.util';
import {useQuantityPicker} from 'src/components/UIElements/QuantityPicker/quantity-picker.hook';
import {
	QuantityMessageProps,
	QuantityPicker,
} from 'src/components/UIElements/QuantityPicker/QuantityPicker';
import {useRadioButton} from 'src/components/UIElements/RadioButton/radio-button.hook';
import {RadioButton} from 'src/components/UIElements/RadioButton/RadioButton';
import {WarningMessage} from 'src/components/UIElements/WarningMessage';
import {useStore} from 'src/contexts/store.context';
import {useImage} from 'src/hooks/image.hook';
import {useMoney} from 'src/hooks/money.hook';
import {getUniqueId} from 'src/utils/uniqueId';

interface PartRegularProps {
	part: ProductObject;
	parent: number[];
	min: number;
	max: number;
	leftover?: number;
	qty: number;
	parentType?: ProductType;
	defaultQuantity?: number;
	hasChildren?: boolean;
	onToggleCustomization?: (parts: ProductPartObject[] | undefined) => void;
}

export const PartRegular = observer(
	({
		part,
		parent,
		min,
		max,
		leftover,
		qty,
		parentType,
		defaultQuantity,
		hasChildren,
		onToggleCustomization,
	}: PartRegularProps) => {
		const {translate, translateProduct} = useTranslate();
		const [comparedParts] = usePartComparator();
		const {handleQuantityChange, maxQuantityReached, resetMaxQuantityMessage} = useQuantityPicker();
		const {handleSelect} = useRadioButton();
		const [formatMoney] = useMoney();
		const uniqueId = useRef(getUniqueId());
		const {image} = useImage(part.imageUrl);

		const {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			customization: {parts},
		} = useStore();

		const customizationItem = useMemo(() => {
			return parent && findCurrentPartByParent(comparedParts, {code: part.code, parent: parent});
		}, [comparedParts, part.code, parent]);

		const partCode = part.code;
		const partName = translateProduct(part).name;

		const urlParams = new URLSearchParams(window.location.search);
		const defaultOption: number = Number(urlParams.get('defaultOptions'));

		const currentValue = !!customizationItem ? customizationItem.qty : qty;
		const currentMax = leftover || leftover === 0 ? currentValue + leftover : max;
		const currentMin = parentType === ProductType.OPTION ? 0 : min;
		const isRadioButton =
			((defaultQuantity === currentMax && currentMax <= 1) || (currentMax <= 1 && max === 1)) &&
			parentType;

		const partPrice = calculatePartPrice(part);
		const formatedPrice = useMemo(() => {
			const shouldDisplayPrice =
				customizationItem &&
				!(
					customizationItem.default === customizationItem.max &&
					customizationItem.included === customizationItem.default
				);
			return partPrice > 0 && shouldDisplayPrice && formatMoney(partPrice);
		}, [customizationItem, partPrice, formatMoney]);

		useEffect(() => {
			if (defaultOption === partCode) void handleSelect({code: partCode, qty: 1, parent: parent});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [defaultOption, parent, partCode]);

		useEffect(() => {
			if (maxQuantityReached && currentMax > currentValue) {
				resetMaxQuantityMessage();
			}
		}, [maxQuantityReached, currentMax, currentValue, resetMaxQuantityMessage]);

		const onSelectRadioButton = useCallback(() => {
			handleSelect({code: partCode, qty: 1, parent: parent});
		}, [handleSelect, parent, partCode]);

		const onSelected = (e: any) => {
			e.stopPropagation();
			isRadioButton && onSelectRadioButton();
		};

		const onCustomizeClick = () => {
			if (onToggleCustomization && hasChildren) {
				onToggleCustomization(part.parts);
			}
		};

		return (
			<div className="parts__item" onClick={onSelected}>
				<div className="parts__customization-container">
					<div className="parts__description">
						<div className="parts__image-container">
							<img src={image} alt=" " className="parts__image" />
						</div>
						<p className={`parts__name txt-s txt-secondary-dark txt-m`}>
							{partName}
							{!!formatedPrice && (
								<span className="parts__price txt-regular txt-s">
									{' (+'}
									{formatedPrice}
									{')'}
								</span>
							)}
						</p>
					</div>
					{hasChildren && (
						<ToggleButton display={true} isOption={false} onCustomizeClick={onCustomizeClick} />
					)}
					{maxQuantityReached && (
						<div className="parts__customization_warning">
							<WarningMessage message={translate(`App.messages.qty_max`)} />
						</div>
					)}
				</div>
				{min === currentMax && parentType !== ProductType.OPTION ? (
					<div className="quantity-picker__container">
						<p className="form-control-quantity-center">{currentMax}</p>
					</div>
				) : (
					<>
						{isRadioButton ? (
							<RadioButton value={currentValue} onSelect={onSelected} />
						) : (
							<div className="parts__qty-wrapper">
								<QuantityPicker
									value={currentValue}
									min={currentMin}
									max={currentMax}
									id={uniqueId.current}
									onChange={({id, value, min, max, operation}: QuantityMessageProps) => {
										handleQuantityChange({
											item: {
												id: id!,
												qty: value!,
												minimum: min,
												maximum: max,
												code: partCode,
												parent: parent,
												operation: operation,
											},
											type: 'part',
										});
									}}
								/>
							</div>
						)}
					</>
				)}
			</div>
		);
	},
);

import {observer} from 'mobx-react-lite';
import React from 'react';
import {useHistory} from 'react-router';
import {ReactSVG} from 'react-svg';

import arrow from 'src/assets/images/icons/arrow-right.svg';
import burger from 'src/assets/images/icons/burger.svg';
import meal from 'src/assets/images/icons/meal.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';

interface ProductChoiceProps {
	mealCode: number;
	itemCode: number;
}
export const ProductChoice = observer((props: ProductChoiceProps) => {
	const {mealCode, itemCode} = props;
	const history = useHistory();
	const {translate} = useTranslate();

	return (
		<div className="product-item__choice-button">
			<button
				onClick={() => history.push(`/product/${itemCode}`)}
				className="choice-button choice-button--top">
				<ReactSVG src={burger} className="button-icon button-icon--burger" />
				<span className="button-title">{translate('ProductChoice.item')}</span>
				<ReactSVG src={arrow} className="button-icon button-icon--arrow" />
			</button>
			<button
				onClick={() => history.push(`/product/${mealCode}?defaultOptions=${itemCode}`)}
				className="choice-button choice-button--bottom">
				<ReactSVG src={meal} className="button-icon button-icon--meal" />
				<span className="button-title">{translate('ProductChoice.meal')}</span>
				<ReactSVG src={arrow} className="button-icon button-icon--arrow" />
			</button>
		</div>
	);
});
